/*eslint-disable*/
const dictionary = {
  /* REUSED LITERALS (please, specfy where) */
  'GENERAL_INFO'                                     : 'General info', //(2, 3, 4)
  'AGREEMENTS_SETTINGS'                              : 'Agreement\'s settings', //(2, 3, 4)
  'ORDERS_BILLING'                                   : 'Order\'s billing', //(2, 3, 4)
  'START_DATE'                                       : 'Start date', //(component, 2)
  'END_DATE'                                         : 'End date', //(component, 2)
  'DATE_RANGE_ERROR'                                 : 'End date must be greater than start date', //(component)
  'DATE_ERROR_MESSAGE'                               : 'Date format is invalid', //(component)
  'DATE_RANGE_ERROR_MESSAGE'                         : 'End date must be after start date', //(component?)
  'DATE_RANGE_ERROR'                                 : 'End date must be after start date', //(component?)
  'NAME'                                             : 'Name', //(1, 2)
  'ID'                                               : 'Id', //(1, 2)
  'EUR'                                              : '€', //(3, 4, 5)
  'MONTHS'                                           : 'months', //(3, 4)
  'AD_DISCONNECTION'                                 : 'Disconnection', //(3, 4)
  'AD_DISPLACEMENT'                                  : 'Displacement', //(3, 4)
  'AD_FAKE_BREAKDOWN'                                : 'Fake breakdown', //(3, 4)
  'AD_GENERIC_ADMINISTRATION'                        : 'Generic administrative charge', //(3, 4)
  'AD_INSTALLATION'                                  : 'Installation', //(3, 4)
  'AD_REFUND_GENERIC_ADMINSTRATION'                  : 'Generic administrative refund', //(3, 4)
  'AD_REFUND_TECHINCAL_ISSUE'                        : 'Technical issue refund', //(3, 4)
  'AD_SERVICE_CHANGE'                                : 'Service change', //(3, 4)
  'FIXED_IPS'                                        : 'Fixed ip\'s', //(2, 3)
  'PRICE_ERROR'                                      : 'Price is required', //(?)
  'OPTIONAL'                                         : 'Optional',
  'COMMENTS'                                         : 'Comments',
  'CANCEL'                                           : 'Cancel',

  /* WHOLESALE OPERATORS PAGE (1) */
  'WHOLESALE_OPERATORS'                              : 'Wholesale Operators',
  'DOWNLOAD_WHOLESALES_GLOBAL_REPORT'                : 'Download Wholesales Global Report',
  'LAST_REPORT_GENERATED_ON'                         : 'Last global report generated on',
  'SEARCH_OPERATOR_LABEL'                            : 'Or search for an operator',
  'OPERATOR'                                         : 'Operator',
  'TYPE_FOR_SEARCHING'                               : 'Type for searching',
  'ORDERED_BY'                                       : 'Ordered by',
  'COMPLETED_ORDERS'                                 : 'Completed orders',
  'LAST_BILLED'                                      : 'Last billed',

  /* OPERATOR INFO PAGE (2) */
  'OPERATORS_GENERAL_INFO'                           : 'Operator\'s general info',
  'OPERATORS_AGREEMENTS'                             : 'Operator\'s agreements',
  'OPERATORS_CONTACT_LIST'                           : 'Operator\'s contact list',
  'FISCAL_NAME'                                      : 'Fiscal name',
  'FISCAL_NUMBER'                                    : 'Fiscal number',
  'SELFINSTALLER'                                    : 'Self-installer',
  'PULPO_ID'                                         : 'Pulpo id',
  'PHONE'                                            : 'Phone',
  'EMAIL'                                            : 'Email',
  'OPERATOR_IS_NOT_SELFINSTALLER'                    : 'Operator is self-installer',
  'OPERATOR_IS_SELFINSTALLER'                        : 'Operator is not a self-installer',
  'ADD_AGREEMENT'                                    : 'Add agreement',
  'ADD_CONTACT'                                      : 'Add contact',
  'CREATE_AGREEMENT'                                 : 'Create agreement',
  'EDIT_AGREEMENT'                                   : 'Edit agreement',
  'AGREEMENT_NAME'                                   : 'Agreement name',
  'AGREEMENT_NAME_PLACEHOLDER'                       : 'Agreement name',
  'AGREEMENT_NAME_ERROR_MESSAGE'                     : 'Agreement name is required',
  'END_DATE_ERROR_MESSAGE'                           : 'End date is invalid',
  'START_DATE_ERROR_MESSAGE'                         : 'Start date is required',
  'CREATE_CONTACT'                                   : 'Create contact',
  'EDIT_CONTACT'                                     : 'Edit contact',
  'NAME_ERROR_MESSAGE'                               : 'Name is required',
  'ROLE'                                             : 'Role',
  'ROLE_ERROR_MESSAGE'                               : 'Role is required',
  'PHONE_ERROR_MESSAGE'                              : 'Phone is required',
  'EMAIL_ERROR_MESSAGE'                              : 'Email is required',
  'REMOVE_CONTACT'                                   : 'Remove contact',
  'REMOVE_CONTACT_MESSAGE'                           : 'Are you sure you want to remove the contact?',

  /* OPERATOR AGREEMENT PAGE (3) */
  'ADD_OPERATOR_SERVICE'                             : 'Add operator service',
  'EDIT_OPERATOR_SERVICE'                            : 'Edit operator service',
  'APPLY_EXCEPTIONAL_PRICE'                          : 'Apply exceptional price',
  'OPERATOR_SALES_AREAS'                             : 'Operator\'s sales area',
  'EDIT_SERVICE_AREA'                                : 'Edit service area',
  'AREA'                                             : 'Area',
  'INSTALLATION_MODE'                                : 'Installation mode',
  'SERVICE_AREA_INSTALLATION_ERROR'                  : 'Installation mode is required',
  'SERVICE_AREA_AREA_ERROR'                          : 'Area is required',
  'ADD_AREA'                                         : 'Add area',
  'EDIT_AREA'                                        : 'Edit area',
  'DESCRIPTION'                                      : 'Description',
  'SPLIT'                                            : 'Split',
  'DEFAULT'                                          : 'Default',
  'AFTERWARDS'                                       : 'Afterwards',
  'ADD_SERVICE_AREA'                                 : 'Add service area',
  'OPERATOR_PROJECTS'                                : 'Operator\'s projects',
  'DEFAULT_PRICES'                                   : 'Default prices',
  'NO_PROJECTS'                                      : 'No projects',
  'SELECT_PROJECT_TO_ADD'                            : 'Select a project to add',
  'PROJECT_NAME_CODE'                                : 'Project name (Code)',
  'ADD_TO_PROJECT_LIST'                              : 'Add to project\'s list',
  'PROJECT_LIST'                                     : 'Project\'s list',
  'PROJECT_ERROR_MESSAGE'                            : 'Project is required',
  'REMOVE_PROJECT'                                   : 'Remove project',
  'SERVICES'                                         : 'Services',
  'SERVICE'                                          : 'Service',
  'PRICE'                                            : 'Price',
  'INSTALLATION'                                     : 'Installation',
  'MIN_DURATION'                                     : 'Min. duration',
  'INSTALLATION_PRICE'                               : 'Installation price',
  'REMOVE_OPERATOR_SALES_AREA'                       : 'Remove operator sales area',
  'REMOVE_OPERATOR_SALES_AREA_CONFIRMATION'          : 'WARNING! Are you sure you want to delete the sales area from the agreement?',
  'REMOVE_OPERATOR_IP'                	             : 'Remove operator ip',
  'REMOVE_OPERATOR_IP_CONFIRMATION'   	             : 'WARNING! Are you sure you want to delete the ip from the agreement?',
  'SERVICE_PRICE'                                    : 'Service price',
  'SERVICE_PRICE_PLACEHOLDER'                        : 'Service price',
  'MIN_DURATION_PLACEHOLDER'                         : 'Min. duration',
  'SERVICE_PRICE_ERROR'                              : 'Service price is required',
  'MIN_DURATION_ERROR'                               : 'Min. duration is required',
  'INSTALLATION_PRICE_ERROR'                         : 'Installation price is required',
  'SERVICE_ERROR'                                    : 'Service is required',
  'SERVICE_AREA_ERROR'                               : 'Service area is required',
  'INSTALLATION_PRICE_PLACEHOLDER'                   : 'Installation price',
  'PROJECTS_PLACEHOLDER'                             : 'Select a project',
  'PROJECTS_ERROR'                                   : 'Project is required',
  'REMOVE_PROJECT_MESSAGE'                           : 'Are you sure you want to remove the project?',
  'INSTALLATION_ERROR'                               : 'Installation mode is required',
  'PAY_FULL_UPFRONT'                                 : 'Pay full upfront',
  'PAY_FULL_UPFRONT_MONTHLY_REFUND'                  : 'Pay full upfront (Monthly refund)',
  'FULL_DISCOUNT'                                    : 'Full discount',
  'FULL_DISCOUNT_PAY_ON_CANCEL'                      : 'Full discount (Pay on cancel)',
  'FULL_DISCOUNT_PAY_MONTHLY_AND_CANCEL'             : 'Full discount (Pay monthly and cancel)',
  'OPERATORS_SALES_AREA'                             : 'Operators\'s Sales Areas',
  'ADD_PROJECT'                                      : 'Add project',
  'ADD_OPERATOR_IP'                                  : 'Add operator ip',
  'EDIT_OPERATOR_IP'                                 : 'Edit operator ip',
  'REMOVE_OPERATOR_SERVICE'                          : 'Remove operator service',
  'REMOVE_OPERATOR_SERVICE_CONFIRMATION'             : 'Are you sure you want to remove the service?',
  'ADD_PRODUCT'                                      : 'Add product',
  'ADD_IP'                                           : 'Add ip',
  'EDIT_CHARGE'                                      : 'Edit charge',
  'CONCEPT'                                          : 'Concept',
  'PROJECTS'                                         : 'Projects',
  'CHARGES'                                          : 'Charges',
  'DISCOUNTS'                                        : 'Discounts',

  /* OPERATOR ORDERS PAGE (4) */
  'ORDERS_SUMMARY'                                   : 'Orders\' summary',
  'LOAD_SUMMARY'                                     : 'Load summary',
  'CALCULATIONS_FOR'                                 : 'Calculations for',
  'BASED_ON_ALL_BILLABLE_DATA_SINCE'                 : 'based on all billable data since',
  'ORDER_SUMMARY_HAS_ERRORS'                         : 'Last import has errors',
  'STATICIPS'                                        : 'Static ip\'s',
  'RETURNS'                                          : 'Returns',
  'OTHERS'                                           : 'Others',
  'TOTAL'                                            : 'Total',
  'INSTALLATIONS'                                    : 'Installations',
  'ADD_FIXED_IP'                                     : 'Add fixed ip',
  'IP_NUMBER'                                        : 'Ip number',
  'EDIT_FIXED_IP'                                    : 'Edit fixed ip',
  'GENERATE_REPORT_MESSAGE'                          : 'WARNING! There might be still orders to be checked. Are you sure you want to generate a report now?',
  'EDIT_INSTALLATION_ORDER_ADDON_PRODUCT'            : 'Edit installation',
  'ADD_INSTALLATION_ORDER_ADDON_PRODUCT'             : 'Add installation',
  'ADD_SERVICE'                                      : 'Add service',
  'EDIT_SERVICE'                                     : 'Edit service',
  'REMOVE_FIXED_IP'                                  : 'Remove fixed ip',
  'REMOVE_FIXED_IP_CONFIRMATION'                     : 'WARNING! Are you sure you want to delete the fixed ip from the order?',
  'REMOVE_SERVICE'                                   : 'Remove service',
  'REMOVE_SERVICE_CONFIRMATION'                      : 'WARNING! Are you sure you want to delete the service from the order?',
  'GENERATE_REPORT'                                  : 'Generate monthly report',
  'GET_OLD_REPORT'                                   : 'Get old report',
  'ALL'                                              : 'All',
  'CANCELED'                                         : 'Canceled',
  'COMPLETED'                                        : 'Completed',
  'DISCONNECTED'                                     : 'Disconnected',
  'ISSUE'                                            : 'Issue',
  'CONFIMED'                                         : 'Confirmed',
  'ORDERED'                                          : 'Ordered',
  'PLANNED'                                          : 'Planned',
  'VERIFIED'                                         : 'Verified',
  'RESERVED'                                         : 'Reserved',
  'ORDERS_LIST'                                      : 'Orders\' list',
  'DISPLAYED_ORDERS'                                 : 'Displayed orders',
  'EXPORT_CSV'                                       : 'Export CSV',
  'GENERATE_REPORT'                                  : 'Generate report',
  'STATUS'                                           : 'Status',
  'RETURN'                                           : 'Return',
  'ADD_STANDARD_ORDER_ADDON_PRODUCT'                 : 'Add event',
  'EDIT_STANDARD_ORDER_ADDON_PRODUCT'                : 'Edit event',
  'REMOVE_STANDARD_ORDER_ADDON_PRODUCT'              : 'Remove event',
  'REMOVE_STANDARD_ORDER_ADDON_PRODUCT_CONFIRMATION' : 'WARNING! Are you sure you want to delete this event from the order?',
  'STANDARD_ADDON_ORDER_PRODUCTS'                    : 'Events and claims',
  'DISCONNECTION'                                    : 'Disconnection',
  'EDIT_DISCONNECTION_ORDER_ADDON_PRODUCT'           : 'Edit disconnection',
  'PRODUCT_LIFECYCLE'                                : 'Product lifecycle',
  'EVENTS_AND_CLAIMS'                                : 'Events and claims',
  'ADDRESS'                                          : 'Address',
  'INITIAL_FREE_PERIOD'                              : 'Initial free period',
  'SHOW_ONLY_ORDERS_WITH_CHANGES'                    : 'Show only orders with changes',
  'FILTER_ORDERS'                                    : 'Filter orders',
  'MARK_AS_REVISED'                                  : 'Mark as revised',
  'PRICE_PLACEHOLDER'                                : '0.00',
  'SELECT_SNAPSHOT'                                  : 'Select snapshot',
  'SNAPSHOT'                                         : 'Choose one generated report',
  'SEE_REPORT'                                       : 'See report',
  'CATEGORY'                                         : 'Categoría',
  'VERIFY_ORDER_MESSAGE'                             : 'WARNING! Are you sure you want to verify this order?',
  'VERIFY_ORDER'                                     : 'Verify order',
  'VERIFY_EXCEPTIONAL_PRICE'                         : 'Verify exceptional price',
  'REQUEST_EXCEPTIONAL_PRICE'                        : 'The operator has requested an exceptional price in this order',
  'VERIFY'                                           : 'Verify',
  'CHARGE'                                           : 'Charge',
  'DISCOUNT'                                         : 'Discount',
  'LOCATION'                                         : 'Location',
  'ZIP_CODE'                                         : 'Zip code',

  /* MONTH REPORT PAGE (5) */
  'GO_BACK'                                          : 'Go back',
  'CLOSE_REPORT'                                     : 'Close report',
  'DOWNLOAD'                                         : 'Download',
}

module.exports = dictionary
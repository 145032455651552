const
languages = require('./lang'),
config    = require('./config'),
moment    = require('moment')

class LocaleService
{
  constructor()
  {
    this.moment = moment

    this.setLang()
    this.setLocaleDates()
  }

  setLang(lang = config.fallBackLang)
  {
    this.lang = lang
  }

  setLocaleDates(lang = config.fallBackLang)
  {
    if(config.dates)
    {
      this.dates = config.dates
      this.moment.locale(config.dates)
    }
    else
    {
      this.dates = lang
      this.moment.locale(lang)
    }
  }

  translate(key)
  {
    let dictionary = languages[this.lang]

    return this.getKeyValue(dictionary, key)
  }

  getMomentDate(value, format)
  {
    if(value)
      return this.moment(value, this.getDateOnlyPlaceholder())
    else
      return undefined
  }

  formatDate(value, valueFormat, isStrict, format)
  {
    return this.moment(value, valueFormat, isStrict).format(format)
  }

  getDateOnlyPlaceholder()
  {
    return this.moment()._locale._longDateFormat.L
  }

  getKeyValue(dictionary, key)
  {
    if(dictionary && dictionary[key])
      return dictionary[key]
    else
      return key
  }
}

module.exports = LocaleService

/*eslint-disable*/
const dictionary = {
  /* REUSED LITERALS (please, specfy where) */
  'GENERAL_INFO'                                     : 'Información general', //(2, 3, 4)
  'AGREEMENTS_SETTINGS'                              : 'Configuración de contrato', //(2, 3, 4)
  'ORDERS_BILLING'                                   : 'Facturación de órdenes', //(2, 3, 4)
  'START_DATE'                                       : 'Fecha de inicio', //(component, 2)
  'END_DATE'                                         : 'Fecha de fin', //(component, 2)
  'DATE_ERROR_MESSAGE'                               : 'El formato de fecha es erróneo', //(component)
  'DATE_RANGE_ERROR_MESSAGE'                         : 'La fecha final debe ser posterior a la fecha de inicio', //(component?)
  'DATE_RANGE_ERROR'                                 : 'La fecha final debe ser posterior a la fecha de inicio', //(component?)
  'NAME'                                             : 'Nombre', //(1, 2)
  'ID'                                               : 'Id', //(1, 2)
  'EUR'                                              : '€', //(3, 4, 5)
  'MONTHS'                                           : 'meses', //(3, 4)
  'AD_DISCONNECTION'                                 : 'Desconexión', //(3, 4)
  'AD_DISPLACEMENT'                                  : 'Desplazamiento', //(3, 4)
  'AD_FAKE_BREAKDOWN'                                : 'Falsa avería', //(3, 4)
  'AD_GENERIC_ADMINISTRATION'                        : 'Cargo por Administración', //(3, 4)
  'AD_INSTALLATION'                                  : 'Instalación', //(3, 4)
  'AD_REFUND_GENERIC_ADMINSTRATION'                  : 'Devolución por Administración', //(3, 4)
  'AD_REFUND_TECHINCAL_ISSUE'                        : 'Devolución por incidencia técnica', //(3, 4)
  'AD_SERVICE_CHANGE'                                : 'Service change', //(3, 4)
  'FIXED_IPS'                                        : 'Ips estáticas', //(2, 3)
  'PRICE_ERROR'                                      : 'Requiere precio', //(?)
  'OPTIONAL'                                         : 'Opcional',
  'COMMENTS'                                         : 'Comentarios',
  'CANCEL'                                           : 'Cancelar',

  /* WHOLESALE OPERATORS PAGE (1) */
  'WHOLESALE_OPERATORS'                              : 'Operadores Wholesale',
  'DOWNLOAD_WHOLESALES_GLOBAL_REPORT'                : 'Descargar reporte global de Operadores',
  'LAST_REPORT_GENERATED_ON'                         : 'Último reporte global generado en',
  'SEARCH_OPERATOR_LABEL'                            : 'O busque un operador',
  'OPERATOR'                                         : 'Operador',
  'TYPE_FOR_SEARCHING'                               : 'Escriba para buscar',
  'ORDERED_BY'                                       : 'Ordenado por',
  'COMPLETED_ORDERS'                                 : 'Órdenes completadas',
  'LAST_BILLED'                                      : 'Última facturación',

  /* OPERATOR INFO PAGE (2) */
  'OPERATORS_GENERAL_INFO'                           : 'Información general',
  'OPERATORS_AGREEMENTS'                             : 'Contratos',
  'OPERATORS_CONTACT_LIST'                           : 'Lista de contactos',
  'FISCAL_NAME'                                      : 'Nombre fiscal',
  'FISCAL_NUMBER'                                    : 'Número fiscal',
  'SELFINSTALLER'                                    : 'Autoinstalador',
  'PULPO_ID'                                         : 'Pulpo id',
  'PHONE'                                            : 'Teléfono',
  'EMAIL'                                            : 'Email',
  'OPERATOR_IS_NOT_SELFINSTALLER'                    : 'El operador es autoinstalador',
  'OPERATOR_IS_SELFINSTALLER'                        : 'El operador no es autoinstalador',
  'ADD_AGREEMENT'                                    : 'Añadir contrato',
  'ADD_CONTACT'                                      : 'Añadir contacto',
  'CREATE_AGREEMENT'                                 : 'Crear contrato',
  'EDIT_AGREEMENT'                                   : 'Editar contrato',
  'AGREEMENT_NAME'                                   : 'Nombre del contrato',
  'AGREEMENT_NAME_PLACEHOLDER'                       : 'Nombre del contrato',
  'AGREEMENT_NAME_ERROR_MESSAGE'                     : 'Requiere Nombre del contrato',
  'END_DATE_ERROR_MESSAGE'                           : 'La fecha final es errónea',
  'START_DATE_ERROR_MESSAGE'                         : 'Requiere fecha de inicio',
  'CREATE_CONTACT'                                   : 'Crear contacto',
  'EDIT_CONTACT'                                     : 'Editar contacto',
  'NAME_ERROR_MESSAGE'                               : 'Requiere nombre',
  'ROLE'                                             : 'Rol',
  'ROLE_ERROR_MESSAGE'                               : 'Requiere rol',
  'PHONE_ERROR_MESSAGE'                              : 'Requiere teléfono',
  'EMAIL_ERROR_MESSAGE'                              : 'Requiere email',
  'REMOVE_CONTACT'                                   : 'Eliminar contacto',
  'REMOVE_CONTACT_MESSAGE'                           : '¡CUIDADO! ¿Confirma que desea eliminar este contacto?',

  /* OPERATOR AGREEMENT PAGE (3) */
  'ADD_OPERATOR_SERVICE'                             : 'Añadir servicio al operador',
  'EDIT_OPERATOR_SERVICE'                            : 'Editar servicio del operador',
  'APPLY_EXCEPTIONAL_PRICE'                          : 'Aplicar precio excepcional',
  'OPERATOR_SALES_AREAS'                             : 'Áreas de cobertura',
  'EDIT_SERVICE_AREA'                                : 'Editar área de cobertura',
  'AREA'                                             : 'Area',
  'INSTALLATION_MODE'                                : 'Modo de instalación',
  'SERVICE_AREA_INSTALLATION_ERROR'                  : 'Requiere modo de instalación',
  'SERVICE_AREA_AREA_ERROR'                          : 'Requiere área',
  'ADD_AREA'                                         : 'Añadir área',
  'EDIT_AREA'                                        : 'Editar área',
  'DESCRIPTION'                                      : 'Descripción',
  'SPLIT'                                            : 'Partido',
  'DEFAULT'                                          : 'Por defecto',
  'AFTERWARDS'                                       : 'Posterior',
  'ADD_SERVICE_AREA'                                 : 'Añadir área de cobertura',
  'OPERATOR_PROJECTS'                                : 'Proyectos del operador',
  'DEFAULT_PRICES'                                   : 'Precios por defecto',
  'NO_PROJECTS'                                      : 'Sin proyectos',
  'SELECT_PROJECT_TO_ADD'                            : 'Selecciona un proyecto a añadir',
  'PROJECT_NAME_CODE'                                : 'Nombre de proyecto (código)',
  'ADD_TO_PROJECT_LIST'                              : 'Añadir a lista de proeyctos',
  'PROJECT_LIST'                                     : 'Lista de proyectos',
  'PROJECT_ERROR_MESSAGE'                            : 'Requiere un proyecto',
  'REMOVE_PROJECT'                                   : 'Elimnar proyecto',
  'SERVICES'                                         : 'Servicios',
  'SERVICE'                                          : 'Servicio',
  'SERVICE_PLACEHOLDER'                              : 'Servicio',
  'PRICE'                                            : 'Precio',
  'INSTALLATION'                                     : 'Instalación',
  'MIN_DURATION'                                     : 'Duración mínima',
  'INSTALLATION_PRICE'                               : 'Precio de instalación',
  'REMOVE_OPERATOR_SALES_AREA'                       : 'Eliminar área de cobertura',
  'REMOVE_OPERATOR_SALES_AREA_CONFIRMATION'          : '¡CUIDADO! ¿Confirma que desea eliminar este área de cobertura del contrato?',
  'REMOVE_OPERATOR_IP'                	             : 'Eliminar ip del operador',
  'REMOVE_OPERATOR_IP_CONFIRMATION'   	             : '¡CUIDADO! ¿Confirma que desea eliminar esta IP del contrato?',
  'SERVICE_PRICE'                                    : 'Precio de servicio',
  'SERVICE_PRICE_PLACEHOLDER'                        : 'Precio de servicio',
  'MIN_DURATION_PLACEHOLDER'                         : 'Duración mínima',
  'SERVICE_PRICE_ERROR'                              : 'Requiere precio de servicio ',
  'MIN_DURATION_ERROR'                               : 'Requiere duración mínima ',
  'INSTALLATION_PRICE_ERROR'                         : 'Requiere precio de instalación ',
  'SERVICE_ERROR'                                    : 'Requiere servicio',
  'SERVICE_AREA_ERROR'                               : 'Requiere área de cobertura',
  'INSTALLATION_PRICE_PLACEHOLDER'                   : '0.00',
  'PROJECTS_PLACEHOLDER'                             : 'Seleccionar proyecto',
  'PROJECTS_ERROR'                                   : 'Requiere proyecto',
  'REMOVE_PROJECT_MESSAGE'                           : '¡CUIDADO! ¿Confirma que desea eliminar este proyecto del contrato?',
  'INSTALLATION_ERROR'                               : 'Requiere modo de instalación',
  'PAY_FULL_UPFRONT'                                 : 'Pago por adelantado',
  'PAY_FULL_UPFRONT_MONTHLY_REFUND'                  : 'Devolución mensual',
  'FULL_DISCOUNT'                                    : 'Descuento completo',
  'FULL_DISCOUNT_PAY_ON_CANCEL'                      : 'Descuento con pago en cancelación',
  'FULL_DISCOUNT_PAY_MONTHLY_AND_CANCEL'             : 'Descuento con pago mensual',
  'OPERATORS_SALES_AREA'                             : 'Áreas de cobertura',
  'ADD_PROJECT'                                      : 'Añadir proyecto',
  'ADD_OPERATOR_IP'                                  : 'Añadir ip de operador',
  'EDIT_OPERATOR_IP'                                 : 'Editar ip de operador',
  'REMOVE_OPERATOR_SERVICE'                          : 'Eliminar servicio',
  'REMOVE_OPERATOR_SERVICE_CONFIRMATION'             : '¡CUIDADO! ¿Confirma que desea eliminar este servicio del operador?',
  'ADD_PRODUCT'                                      : 'Añadir producto',
  'ADD_IP'                                           : 'Añadir ip',
  'EDIT_CHARGE'                                      : 'Editar cargo',
  'CONCEPT'                                          : 'Concepto',
  'PROJECTS'                                         : 'Proyectos',
  'CHARGES'                                          : 'Cargos',
  'DISCOUNTS'                                        : 'Descuentos',

  /* OPERATOR ORDERS PAGE (4) */
  'ORDERS_SUMMARY'                                   : 'Resumen de órdenes',
  'LOAD_SUMMARY'                                     : 'Cargar resumen',
  'CALCULATIONS_FOR'                                 : 'Cálculos de',
  'BASED_ON_ALL_BILLABLE_DATA_SINCE'                 : 'basados en la información facturable desde',
  'ORDER_SUMMARY_HAS_ERRORS'                         : 'El último importe tiene errores',
  'STATICIPS'                                        : 'IPs Fijas',
  'RETURNS'                                          : 'Devoluciones',
  'OTHERS'                                           : 'Otros',
  'TOTAL'                                            : 'Total',
  'INSTALLATIONS'                                    : 'Instalaciones',
  'ADD_FIXED_IP'                                     : 'Añadir ip estática',
  'IP_NUMBER'                                        : 'Dirección Ip',
  'EDIT_FIXED_IP'                                    : 'Editar ip estática',
  'GENERATE_REPORT_MESSAGE'                          : '¡CUIDADO! Pueden quedar órdenes por recisar. ¿Confirma que quiere generar un reporte ahora?',
  'EDIT_INSTALLATION_ORDER_ADDON_PRODUCT'            : 'Editar instalación',
  'ADD_INSTALLATION_ORDER_ADDON_PRODUCT'             : 'Añadir instalación',
  'ADD_SERVICE'                                      : 'Añadir servicio',
  'EDIT_SERVICE'                                     : 'Editar servicio',
  'REMOVE_FIXED_IP'                                  : 'Eliminar ip estática',
  'REMOVE_FIXED_IP_CONFIRMATION'                     : '¡CUIDADO! ¿Confirma que desea eliminar esta ip estática de la orden?',
  'REMOVE_SERVICE'                                   : 'Eliminar servicio',
  'REMOVE_SERVICE_CONFIRMATION'                      : '¡CUIDADO! ¿Confirma que desea eliminar este servicio de la orden?',
  'GENERATE_REPORT'                                  : 'Generar reporte mensual',
  'GET_OLD_REPORT'                                   : 'Ver reportes antiguos',
  'ALL'                                              : 'Todas',
  'CANCELED'                                         : 'Cancelada',
  'COMPLETED'                                        : 'Completada',
  'DISCONNECTED'                                     : 'Desconectada',
  'ISSUE'                                            : 'En revisión',
  'CONFIMED'                                         : 'Confirmada',
  'ORDERED'                                          : 'Ordenada',
  'PLANNED'                                          : 'Planificada',
  'VERIFIED'                                         : 'Verificada',
  'RESERVED'                                         : 'Reservada',
  'ORDERS_LIST'                                      : 'Lista de órdenes',
  'DISPLAYED_ORDERS'                                 : 'Órdenes a mostrar',
  'EXPORT_CSV'                                       : 'Exportar CSV',
  'GENERATE_REPORT'                                  : 'Generar reporte',
  'STATUS'                                           : 'Estado',
  'RETURN'                                           : 'Devolución',
  'ADD_STANDARD_ORDER_ADDON_PRODUCT'                 : 'Añadir evento',
  'EDIT_STANDARD_ORDER_ADDON_PRODUCT'                : 'Editar evento',
  'REMOVE_STANDARD_ORDER_ADDON_PRODUCT'              : 'Eliminar evento',
  'REMOVE_STANDARD_ORDER_ADDON_PRODUCT_CONFIRMATION' : '¡CUIDADO!  ¿Confirma que desea eliminar este evento de la orden?',
  'STANDARD_ADDON_ORDER_PRODUCTS'                    : 'Eventos y reclamaciones',
  'DISCONNECTION'                                    : 'Desconexión',
  'EDIT_DISCONNECTION_ORDER_ADDON_PRODUCT'           : 'Editar desconexión',
  'PRODUCT_LIFECYCLE'                                : 'Vida del producto',
  'EVENTS_AND_CLAIMS'                                : 'Evebtos y reclamaciones',
  'ADDRESS'                                          : 'Dirección',
  'INITIAL_FREE_PERIOD'                              : 'Período gratuíto inicial',
  'SHOW_ONLY_ORDERS_WITH_CHANGES'                    : 'Mostrar sólo órdenes con cambios',
  'FILTER_ORDERS'                                    : 'Filtrar órdenes',
  'MARK_AS_REVISED'                                  : 'Marcar como revisado',
  'PRICE_PLACEHOLDER'                                : '0.00',
  'SELECT_SNAPSHOT'                                  : 'Selecciona reporte',
  'SNAPSHOT'                                         : 'Elige uno de los reportes generados',
  'SEE_REPORT'                                       : 'Ver reporte',
  'CATEGORY'                                         : 'Categoría',
  'VERIFY_ORDER_MESSAGE'                             : '¡CUIDADO! ¿Confirma que desea verificar esta orden?',
  'VERIFY_ORDER'                                     : 'Verificar orden',
  'VERIFY_EXCEPTIONAL_PRICE'                         : 'Verificar precio excepcional',
  'REQUEST_EXCEPTIONAL_PRICE'                        : 'El operador ha solicitado un precio excepcional para esta orden',
  'VERIFY'                                           : 'Verificar',
  'CHARGE'                                           : 'Cargo',
  'DISCOUNT'                                         : 'Descuento',
  'LOCATION'                                         : 'Población',
  'ZIP_CODE'                                         : 'Código postal',

  /* MONTH REPORT PAGE (5) */
  'GO_BACK'                                          : 'Volver',
  'CLOSE_REPORT'                                     : 'Cerrar reporte',
  'DOWNLOAD'                                         : 'Download',
}

module.exports = dictionary